@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?cx706w');
  src:  url('../fonts/icomoon.eot?cx706w#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?cx706w') format('truetype'),
    url('../fonts/icomoon.woff?cx706w') format('woff'),
    url('../fonts/icomoon.svg?cx706w#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.font-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-bottom:before {
  content: "\e905";
}
.icon-arrow-right:before {
  content: "\e906";
}
.icon-arrow-top:before {
  content: "\e907";
}
.icon-cart:before {
  content: "\e908";
}
.icon-burger:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e90a";
}
.icon-dropdown:before {
  content: "\e900";
}
.icon-heart-fill:before {
  content: "\e90b";
}
.icon-heart:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-play:before {
  content: "\ea1c";
}
.icon-pause:before {
  content: "\ea1d";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin:before {
  content: "\eaca";
}
