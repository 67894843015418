table {
  width: 100%;
  border: 0;
  margin: 40px 0 0;

  tr.even, tr.odd {
    background: transparent;
  }

  th, td {
    height: 40px;
    border: 0;
    padding: 15px 0 15px 20px;
  }

  th {
    font-size: 16px;
    border: 0;
    color: $red;
    font-weight: 700;
    background: transparent;
    border-bottom: 1px solid $red;
  }

  td {
    font-size: 14px;

    a {
      font-size: 16px;
      color: $black;

      &:hover {
        color: $red;
      }
    }
  }


  tbody {
    tr:nth-child(2n+1) {
      background: #f0f0f0;
    }
  }
}

.inverse-table-simple {

  th, td {
    background: #fff;
    border: 0;
    border-bottom: 1px solid #e0e0e0;
    color: #333333;
    font-size: 16px;
  }

  th {
    font-weight: 700;
  }

  td {
    font-weight: 400;
  }
}



.inverse-table {

  th {
    padding: 10px;
    background: #f0f0f0;
    color: $red;
    border-right: 1px solid $red;
    border-bottom: 0;
  }

  td {
    padding: 10px;
  }

  tr:nth-child(2n) th {
    background: #eaeaea;
  }

  tbody {
    tr:nth-child(2n) {
      background: #f0f0f0;
    }
    tr:nth-child(2n+1) {
      background: #fff;
    }
  }
}

