

.node--membership--full {
  @include clearfix();
  margin-top: 50px;

  .right-col,
  .left-col {
    padding: 0 20px;

    @include breakpoint($tab) {
      display: inline-block;
      width: 60%;
      float: left;
    }
  }

  .left-col {
    @include breakpoint($tab) {
      width: 40%;
      padding: 0 40px;
    }

    .field--name-field-image {
      img {
        max-width: 375px;
        width: 100%;
        height: auto;
      }
    }
  }

  .right-col {
    margin-top: 30px;

    @include breakpoint($tab) {
      margin-top: 0;
    }

    h1 {
      text-align: left;
      font-size: 32px;
      line-height: 38px;
      margin: 0;
      clear: both;
    }

    h2.product-subtitle {
      font-size: 18px;
      margin: 5px 0 0;
    }

    .sku {
      float: left;
      font-weight: 300;
      font-size: 13px;
      color: $grey;
      .commerce-product-sku-label {
        display: none;
      }
    }

    .publisher {
      float: right;
      label,
      a,
      .field--name-field-publisher {
        display: inline-block;
        font-weight: 300;
        font-size: 13px;
        color: $grey;
      }
      label {
        margin-right: 4px;
        margin-bottom: 0;
      }
    }

    .author {
      margin-top: 15px;
      a {
        font-size: 16px;
        color: $grey;
      }
    }

    .prices-wrapper {
      margin: 25px 0 0;
      float: left;
      .final-price,
      .initial-price {
        display: inline-block;
        font-weight: 400;
      }

      .final-price {
        font-size: 32px;
        margin-right: 20px;
      }

      .initial-price {
        text-decoration: line-through;
        font-size: 26px;
        color: $grey;
      }
    }

    .duration-wrapper {
      clear: both;
      padding-top: 30px;
      display: block;

      .field--name-field-months {
        font-size: 20px;
      }
    }

    .description-summary {
      padding: 30px 0;
      clear: both;
      line-height: 19px;
    }

    .product-wrapper {
      .form-item-quantity {
        float: left;
        margin: 0;

        label {
          margin: 0 5px 0 0;
          display: inline-block;
        }
        input {
          display: inline-block;
          width: 85px;
          border-width: 2px;
        }
      }

      .action-buttons {
        clear: both;
        padding: 30px 0 0;

        .cart-button-wrapper {
          display: inline-block;
          position: relative;

          input {
            padding-right: 45px;
            font-size: 12px;
          }

          &:before {
            @extend .font-icon;
            @extend .icon-cart;
            font-size: 15px;
            color: #333;
            position: absolute;
            right: 20px;
            top: 12px;
            pointer-events: none;
          }

          &:hover {
            &:before {
              color: $red;
            }
          }
        }

        .wishlist-wrapper {
          display: inline-block;
          margin-top: 20px;

          @include breakpoint($desk) {
            margin-left: 5px;
            margin-top: 0;
          }

          a {
            border: 1px solid #dadada;
            border-radius: 22px;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 12px 45px 12px 20px;
            color: $grey;
            position: relative;
            display: block;

            &.in-wishlist {
              padding: 12px 45px 12px 10px;
            }

            &:before {
              @extend .font-icon;
              @extend .icon-heart;
              font-size: 15px;
              color: $grey;
              position: absolute;
              top: 14px;
              right: 20px;
            }

            &:focus,
            &:hover {
              text-decoration: none;
              border-color: #000;
              color: #000;
            }
          }
        }
      }
    }

    .tags-wrapper {
      margin: 30px 0 0;
      position: relative;
      font-size: 12px;
      padding-top: 10px;
      padding-left: 10px;
      font-weight: 700;
      border-top: 1px solid #dadada;

      .tags-inner {
        @include pie-clearfix;

        & > div {
          float: left;
        }
      }

      .tags-inner:before {
        content: '#';
        display: block;
        position: absolute;
        top: 10px;
        left: 0;
      }

      .field__item {
        display: inline-block;
        position: relative;
        margin-right: 6px;
        border-bottom: 1px solid $red;

        a:hover {
          color: $red;
          text-decoration: none;
        }

        &:after {
          content: ',';
          position: absolute;
          top: 0;
          right: -3px;
          display: block;
        }

        &:last-child:after {
          display: none;
        }
      }
    }

    .share-product {
      margin-top: 60px;

      h4 {
        margin: 0 8px 0 0;
        font-size: 13px;
        line-height: 25px;
        float: left;
        font-weight: 700;
        display: inline-block;
      }

      .share-product-wrapper {
        display: inline-block;

        a {
          display: inline-block;
          height: 25px;
          width: 25px;
          border-radius: 20px;
          background: $red;
          margin: 0;
          text-align: center;
          position: relative;
          overflow: hidden;
          color: transparent;

          &.facebook-share:before {
            position: absolute;
            @extend .font-icon;
            @extend .icon-facebook;
            padding: 4px 9px;
            color: #333;
          }

          &.twitter-share:before {
            position: absolute;
            @extend .font-icon;
            @extend .icon-twitter;
            padding: 5px 3px;
            color: #333;
          }

          &:hover,
          &:focus {
            &:after {
              background: #000;
              color: $red;
              text-decoration: none;
            }
          }


        }
      }
    }
  }

  .bottom-section {
    clear: both;
    margin: 0 auto;
    width: 100%;
    padding: 70px 20px 100px;

    .courses {
      @include pie-clearfix;
    }

    h3 {
      text-align: center;
      margin: 0 0 60px;
      font-size: 20px;
    }

    .details-tabs {
      display: table;
      margin: 0 auto;
      text-align: center;

      li {
        display: inline-block;
        margin: 0 15px;
        padding-bottom: 5px;

        a {
          font-weight: 400;
          color: $grey;
          font-size: 13px;
          position: relative;

          &:hover,
          &.active {
            color: #000;
            text-decoration: none;

            &:after {
              width: 55px;
              height: 1px;
              background-color: $red;
              content: '';
              display: block;
              top: 22px;
              left: 0;
              right: 0;
              margin: 0 auto;
              position: absolute;
            }
          }
        }
      }
    }

    .tabs-wrapper {
      margin-top: 30px;
      & > * {
        display: none;
        font-size: 14px;
        font-weight: 300;
        color: $grey;
        line-height: 19px;


        &.active-tab {
          display: block;
        }
      }

    }
  }
}

.node--product--teaser {
  text-align: center;

  .product-image {
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;

    & > a {
      display: block;
      position: relative;
      z-index: 0;

      span.overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        @include opacity(0);
        transition: all .3s;

        strong {
          text-align: center;
          display: block;
          color: #fff;
          text-transform: uppercase;
          font-weight: 400;
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 24px;

          small {
            font-size: 20px;
          }

          hr {
            display: block;
            margin: 10px auto;
            width: 20px;
            height: 1px;
            background: #fff;
          }
        }
      }

      &:hover span.overlay {
        @include opacity(1);
      }

      img {
        display: block;
        max-width: 100%;

        &.alt-image {
          @include transition-duration(0.5s);
          @include opacity(0);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
          z-index: 1;
        }
      }

      &.alt-image:hover img.alt-image {
        @include opacity(1);
      }
    }

    span.flag-wishlist {
      display: block;
      float: left;
      position: absolute;
      bottom: 10px;
      left: 10px;
      z-index: 9;

      .flag-throbber {
        display: none!important;
      }

      a {
        font-weight: 400;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 0px;

        &:hover {
          font-size: 8px;
        }

        &:before {
          vertical-align: middle;
          margin: 0 5px 0 0;
          font-size: 20px;
        }
      }
    }
  }

  h2 {
    margin-bottom: 10px;
    font-size: 12px;

    a {
      font-weight: 300;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .commerce-product-field {
    font-size: 18px;
    font-weight: 300;

    sup {
      font-size:12px;
      position: relative;
      top: 2px;
      left: -2px;
    }
  }
}

.custom-field .field__label {
  margin-right: 3px;
}
