input[type="text"],
input[type="password"],
input[type="email"],
button,
textarea {
  border: 2px solid #cdcdcd;
  background: transparent;
  font-size: 14px;
  padding: 10px 40px 10px 15px;
  width: 100%;
  resize: none;
  border-radius: 3px;

  &:hover,
  &:focus {
    outline: none;

    &.error {
      border-color: red;
    }

    // &.valid {
    //   border-color: #5fad86;
    // }
  }

  // &.error {
  //   border-color: $orange;
  //   border-width: 1px;
  // }

  // &.valid {
  //   border-color: #5fad86;
  //   border-width: 1px;
  // }
}

.selector {
  border: 2px solid #cdcdcd;
  background: transparent;
  font-size: 14px;
  padding: 10px 40px 10px 15px;
  width: 100%;
  resize: none;
  border-radius: 3px;
  width: 100%;
  resize: none;
  text-align: left;
  position: relative;
  cursor: pointer;

  &:before {
    @extend .font-icon;
    @extend .icon-dropdown;
    font-size: 8px;
    color: $red;
    position: absolute;
    right: 11px;
    top: 15px;
  }

  select {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}

input[type="submit"],
.membership-link {
  background: $red;
  color: #fff;
  border-radius: 3px;
  outline: none;
  font-size: 15px;
  text-transform: uppercase;
  border: 0;
  padding: 0 20px;
  line-height: 40px;

  &:hover {
    background: $black;
    color: #fff;
  }
}

.form-type-checkbox label {
  padding-left: 5px;
  position: relative;
  top: 5px;
  margin-bottom: 5px;
}
input[type="checkbox"] {
    margin: 4px 15px 0;
}


.form-item .description {
  margin-top: 5px;
  font-size: 12px;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + div,
input[type="radio"] + label {
  position: relative;

  &:before {
    width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    border-radius: 50%;
    display: block;
    content: "";
    background-color: #ffffff;
    border: 4px solid $orange;
    position: absolute;
    top: 3px;
    left: 0;
  }
}

input[type="radio"] + div {
  &:before {
    width: 10px;
    height: 10px;
    font-size: 8px;
    line-height: 10px;
    top: 6px;
  }
}

.form-type-radio label {
  padding-left: 20px;
}

input[type="radio"]:checked + div,
input[type="radio"]:checked + label {
  &:before {
    background: $orange;
  }
}

.form-item {
  margin-bottom: 20px;

  label {
    font-size: 12px;
    color: $red;
    text-transform: uppercase;
    margin: 0 0 4px;

    &.error {
      color: $orange;
      margin-top: 5px;
    }
  }
}

.form-actions {
  clear: both;
}

.search-form-block {
  position: relative;
  width: 100%;
  max-width: 312px;
  margin: 0 0 0 auto;
  display: block;

  &.focused {
    position: fixed;
    max-width: none;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50px;
    z-index: 499;
    border-radius: 20px;
    box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.1);

    input[type="text"] {
      opacity: 0.8;
    }
  }

  input[type="text"] {
    padding-left: 40px;
  }

  .form-actions {
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 50%;
    background: none;
    background-color: $orange;
    font-size: 0px;
    width: 30px;
    height: 30px;
    overflow: hidden;

    &:before {
      @extend .font-icon;
      @extend .icon-search;
      font-size: 20px;
      line-height: 30px;
    }

    &:after {
      position: absolute;
      color: #000;
      font-family: fontawesome;
      font-size: 18px;
      content: "\f002";
    }

    .form-submit {
      width: 100%;
      height: 100%;
      background: none;
      border: 0;
      border-radius: 50%;
      font-size: 0;
    }
  }
}

// Contact Form

.node--webform--full {
  .field--name-body {
    text-align: center;
    margin-bottom: 60px;
  }
}

.webform-client-form {
  @include pie-clearfix;
  max-width: 840px;
  margin: 0 auto;

  .form-item {
    width: 100%;

    @include breakpoint($tab) {
      width: 50%;
      float: left;
      display: inline-block;
      padding: 0 10px;
    }

    &.webform-component-textarea {
      @include breakpoint($tab) {
        width: 100%;
      }
    }
  }

  .form-submit {
    display: block;
    margin: 10px auto 0;
    font-weight: 700;
  }

}
