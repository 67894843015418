.node-type-course {

  .l-content.container {
    width: 100%;
  }

  .video-wrapper {
    @include pie-clearfix;
    position: relative;

    .field--name-field-instrument {
      position: absolute;
      background: $medium-grey;
      font-size: 19px;
      padding: 5px 20px 5px 40px;
    }

    .xpress {
      left: 0;
      top: 34px;
      font-size: 19px;
      padding: 5px 20px 5px 40px;
    }

  }

  .rating-wrapper {
    margin: 40px 0;
    float: left;

    h4 {
      margin: 0 8px 0 0;
      font-size: 13px;
      line-height: 25px;
      float: left;
      font-weight: 700;
    }

    .fivestar-widget {
      float: right;
    }

    div.fivestar-widget-static .star {
      background: none;
      width: 18px;
      height: 18px;
    }

    div.fivestar-widget .star,
    div.fivestar-widget .star a,
    div.fivestar-widget .star.off a,
    div.fivestar-widget-static .star span.off {
      width: 18px;
      height: 18px;
      display: block;
      background: url(../images/star-empty.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 5px;
    }

    div.fivestar-widget .star.hover,
    div.fivestar-widget .star a:hover,
    div.fivestar-widget .star.on a,
    div.fivestar-widget-static .star span.on {
      width: 18px;
      height: 18px;
      display: block;
      background: url(../images/star-full.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .form-item {
      margin: 0;
    }

    .fivestar-default {
      float: right;
    }

    .description {
      float: left;
      font-size: 13px;
      font-weight: 400;
      margin-right: 9px;
      border-bottom: 1px solid $grey;
    }
  }

  .details {
    @include pie-clearfix;

    & > div {
      display: inline-block;
      padding: 15px;
      text-align: left;

      h3 {
        font-size: 25px;
        margin-bottom: 25px;
      }
    }

    .course-description {
      width: 100%;

      @include breakpoint($tab) {
        float: left;
        width: 75%;
      }
    }

    .course-actions {
      width: 100%;

      @include breakpoint($tab) {
        float: left;
        width: 25%;
      }
    }

    .commerce-product-field-commerce-price {
      font-size: 25px;
    }

    .teacher {
      @include pie-clearfix;
      display: block;

      img {
        float: left;
      }

      h4 {
        float: left;
        margin-left: 15px;
        font-size: 20px;
      }
    }

    .see-comments {
      margin-top: 20px;
      display: inline-block;
      background: $red;
      color: #fff;
      padding: 5px 10px;
      border-radius: 4px;
    }

    .action-buttons {
      clear: both;
      padding: 20px 0 0;

      .cart-button-wrapper{
        display: inline-block;
        position: relative;

        .membership-link {
          display: block;
        }

        input,
        .membership-link {
          padding-right: 45px;
          font-size: 12px;
        }

        &:before {
          @extend .font-icon;
          @extend .icon-cart;
          font-size: 20px;
          pointer-events: none;
          color: #fff;
          position: absolute;
          right: 14px;
          top: 9px;
        }

        &:hover {
          &:before {
            color: $red;
          }
        }
      }

      .wishlist-wrapper {
        display: inline-block;
        margin-top: 20px;

        @include breakpoint($desk) {
          margin-left: 5px;
          margin-top: 0;
        }

        a {
          border: 1px solid $red;
          border-radius: 3px;
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
          padding: 12px 45px 12px 20px;
          color: $red;
          position: relative;
          display: block;

          &.in-wishlist {
            padding: 12px 45px 12px 10px;
          }

          &:before {
            @extend .font-icon;
            @extend .icon-heart;
            font-size: 15px;
            color: $red;
            position: absolute;
            top: 14px;
            right: 20px;
          }

          &:focus,
          &:hover {
            text-decoration: none;
            border-color: $black;
            color: $black;

            &:before {
              color: $black;
            }
          }
        }
      }
    }

    .share-course {
      clear: both;

      h4 {
        margin: 0 8px 0 0;
        font-size: 13px;
        line-height: 25px;
        float: left;
        font-weight: 700;
      }

      .share-course-wrapper {
        display: block;
        clear: both;
        padding-top: 10px;

        a {
          display: inline-block;
          height: 25px;
          width: 25px;
          border-radius: 20px;
          background: $red;
          margin: 0;
          text-align: center;
          position: relative;
          overflow: hidden;
          color: transparent;

          &.facebook-share:before {
            position: absolute;
            @extend .font-icon;
            @extend .icon-facebook;
            padding: 5px;
            color: #333;
          }

          &.twitter-share:before {
            position: absolute;
            @extend .font-icon;
            @extend .icon-twitter;
            padding: 5px;
            color: #333;
          }

          &.linkedin-share:before {
            position: absolute;
            @extend .font-icon;
            @extend .icon-linkedin;
            padding: 4px 5px;
            color: #333;
          }

          &:hover,
          &:focus {
            &:after {
              background: #000;
              color: $red;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  #player {
    width: 100%;
    min-height: 450px;

    iframe {
      width: 100%;
      min-height: 450px;
    }
  }

  #lockedplayer {
    height: 450px;
    width: 100%;
    background-position: center;
    background-size: cover;

    a,
    span {
      position: absolute;
      top: 150px;
      max-width: 200px;
      text-align: center;
      left: 0;
      right: 0;
      margin: 0 auto;
      color: #fff;
      background: #ef4030;
      padding: 10px;
    }
  }

  #playlist {
    margin-top: 40px;

    .lesson-info {
      padding: 15px;

      h4 {
        font-weight: 700;
      }
    }

    & > h3 {
      background: $medium-grey;
      padding: 15px;
      outline: none;
      cursor: pointer;
      margin: 0;
      position: relative;
    }

    & > div {
      border: 5px solid $medium-grey;
      color: rgba(0,18,56,0.9);
    }

    & > .completed-lesson:before {
      @extend .font-icon;
      @extend .icon-checkmark;
      position: absolute;
      right: 15px;
      top: 15px;
      color: $red;
      font-size: 20px;
    }

    .play-lesson {
      background: $red;
      margin: 30px 0;
      padding: 0 15px;

      a {
        display: block;
        position: relative;
        color: #fff;
        padding: 15px 35px;
        text-decoration: none;
        font-size: 18px;

        &:hover {
          opacity: 0.8;
        }

        &:before {
          @extend .font-icon;
          @extend .icon-play;
          position: absolute;
          font-size: 18px;
          left: 0;
          top: 13px;
          border: 1px solid #fff;
          border-radius: 50%;
          padding: 3px 4px;
          width: 25px;
          height: 25px;
        }
      }
    }

    .lesson-metadata {
      @include pie-clearfix;
      padding: 15px;
    }

    .lesson-file {
      margin-bottom: 20px;
      font-weight: 700;
      float: left;
      margin-right: 40px;

      a {
        font-weight: 400;
        display: block;
        margin-top: 10px;

        &:before {
          content: "";
          width: 20px;
          height: 20px;
          background-image: url(../images/file_icon.png);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          display: inline-block;
          margin-right: 5px;
          vertical-align: middle;
        }
      }
    }

    .lesson-audio {
      margin-bottom: 20px;
      font-weight: 700;
      float: left;

      audio {
        display: block;
        margin-top: 10px;
      }
    }
  }

  #comments {
    @extend .container;

    .comments__title,
    .comments__form-title {
      font-size: 30px;
      padding-bottom: 20px;
      border-bottom: 1px solid $grey;
    }

    .comment {
      @include pie-clearfix;
      padding: 20px 0;
      border-bottom: 1px solid $grey;

      & > h3,
      & > .links,
      .permalink {
        display: none;
      }

      .comment__info {
        float: left;
        width: 40%;

        .user-picture {
          width: 60px;

          a {
            display: block;
          }

          img {
            width: 100%;
          }
        }

        .submitted {
          font-size: 13px;
          opacity: 0.6;
          margin-top: 10px;
        }
      }

      .comment__content {
        float: right;
        width: 60%;
        font-size: 18px;
      }
    }

    .form-item-subject {
      display: none;
    }

    .form-actions a {
      margin-right: 20px;
    }

    .cke_top,
    .cke_bottom {
      background: $grey;
      background-image: none;
      box-shadow: none;
      border: 0;
    }
  }
}

.xpress {
  text-align: center;
  left: 10px;
  top: 0;
  padding: 5px 30px;
  background-color: rgba(239, 64, 48, 0.8);
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
}
