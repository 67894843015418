@import "compass";
@import "breakpoint";

// Import default variables and icon font.
@import "variables/default.scss";
@import "variables/icons.scss";

// Import boostrap and font-awesome.
@import "bootstrap";
@import "font-awesome";

// Import variables
@import "base/default.scss";

// Import custom mixins.
@import "abstractions/mixins.scss";

// Import base styling.
@import "base/navigation.scss";
@import "base/form.scss";
@import "base/table.scss";

// Import components.
@import "components/tabs.scss";
@import "components/user.scss";
@import "components/newsletter.scss";
@import "components/blog.scss";
@import "components/membership.scss";
@import "components/course.scss";
@import "components/dashboard.scss";

::selection {
  color: #000;
  background: $red;
}

// Layout.
.container {
  @include pie-clearfix;
  @include clearfix;

  margin: 0 auto;
  width: 320px;
  padding: 0;
  // padding: 0 5px;

  @include breakpoint($tab) {
    width: 720px;
    // padding: 0 20px;

    @include breakpoint($desk) {
      width: 1200px;
    }
  }

  &.no-padding {
    padding: 0;
  }
}

.clearfix {
  @include pie-clearfix;
  @include clearfix;
}

.view-col-3 {
  @include clearfix();

  .view-content > * {
    display: inline-block;
    width: 100%;
    float: left;

    @include breakpoint($tab) {
      width: 50%;

      @include breakpoint($desk) {
        width: 33.33333333%;
      }
    }
  }
}

.view-col-4 {
  @include clearfix();

  .view-content > * {
    display: inline-block;
    width: 50%;
    float: left;

    @include breakpoint($tab) {
      width: 25%;
    }
  }
}

.cookie-wrapper {
  background: $red;
  padding: 20px 0;
  .container {
    padding: 0 20px;
  }
  a {
    text-decoration: underline;
  }
  button {
    background: #000;
    border: 0;
    border-radius: 20px;
    color: $red;
    padding: 5px 15px;
    border: 1px solid #000;
    margin-left: 25px;
    width: auto;
    &:hover {
      color: #000;
      border: 1px solid #000;
      background: $red;
    }
  }
}

.l-page {

  h1 {
    font-size: 32px;
    margin: 60px 0 15px;
    font-weight: 700;
  }

  &.blur {
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }
}

.l-header {
  @include pie-clearfix;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  border-width: 0 0 3px 0;
  border-bottom: 2px solid $red;

  .l-header-inner {
    position: relative;
  }

  .header-left {
    float: left;
    width: 66.66%;
  }

  .l-branding {
    display: inline-block;
    float: left;
    width: 33.33%;
    text-align: left;

    .site-logo {
      display: block;
      margin-top: 5px;

      img {
        width: 180px;
        height: auto;
      }
    }

    .site-slogan {
      display: none;
    }
  }

  .branding-menu {
    font-weight: 300;
    font-size: 14px;

    a {
      padding: 5px;

      @include breakpoint($desk) {
        padding: 5px 10px;
      }
    }
  }

  #mobile-menu {
    font-size: 0px;
    padding: 2.5px 0;
    float: right;
    text-decoration: none;
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    top: 10px;
    right: 0;

    @include breakpoint($desk) {
      display: none;
    }

    .line {
      display: block;
      width: 100%;
      height: 2px;
      margin-bottom: 8px;
      background: $black;
    }
  }
}

.l-breadcrumb {
  background-color: $red;
  padding: 15px 0;

  .breadcrumb {
    padding: 0 20px;
    margin: 0;
    background-color: transparent;

    li:before {
      color: #000;
      padding-right: 2px;
    }
  }
}

.l-main {
  @include pie-clearfix;
  @include clearfix;

  .content-wrapper {
    display: inline-block;

    .block__title {
      font-size: 28px;
      text-align: center;
      margin: 0 0 30px;
      font-weight: 700;
    }

    &.with-sidebar {
      margin-top: 50px;
      margin-bottom: 100px;
      padding: 0 20px;

      @include breakpoint($tab) {
        float: left;
        width: 66.666%;
      }
    }

    &.no-sidebar {
      width: 100%;
      position: relative;

      .page-user & {
        padding: 0 20px 100px;
      }
    }

    .page-taxonomy-term & {
      width: 100%;
    }
  }
}

.main-navigation {
  width: 100%;
  border-top: 1px solid $red;
  border-bottom: 1px solid $red;
  margin-top: -9px;

  #mobile-menu {
    font-size: 0px;
    padding: 2.5px 0;
    float: right;
    text-decoration: none;
    width: 25px;
    height: 25px;
    display: block;

    @include breakpoint($desk) {
      display: none;
    }

    &:before {
      display: block;
      font-size: 25px;
      color: black;
    }
  }
}

.hero-banner {

  .slide-wrapper {
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    color: #fff;

    @include breakpoint($desk) {
      padding-left: 215px;
      text-align: left;
    }

    h2 {
      color: #fff;
      font-size: 40px;
      padding-top: 150px;
      margin: 0;
    }

    p {
      font-size: 18px;
      margin: 20px 0 30px;
    }

    a {
      text-decoration: none;
      line-height: 45px;
      padding: 0 25px;
      border: 1px solid $red;
      border-radius: 3px;
      display: inline-block;
      color: $red;
      font-weight: 700;

      &:hover {
        background: $red;
        color: #fff;
      }
    }
  }
}


#block-block-1 {
  float: right;
  margin-top: 20px;
  display: none;

  @include breakpoint($desk) {
    display: block;
  }

  .block__content {

    a {
      line-height: 20px;
      color: $black;
      display: inline-block;
      font-size: 15px;
      padding: 0 20px;

      &:hover {
        color: $red;
        text-decoration: none;
      }

      &.log-in-button {
        border-right: 1px solid $black;
      }
    }
  }
}

// Main Menu
#block-system-main-menu {
  padding: 20px 0 0 70px;
  display: none;
  float: left;

  .main-navigation & {
    display: none;
  }

  @include breakpoint($desk) {
    display: block;

    .main-navigation & {
      display: inline-block;
    }
  }

  li {
    padding-left: 15px;
    padding-right: 15px;

    a {
      color: $black;
      padding: 0;
      font-size: 17px;

      &:hover {
        color: $red;
      }
    }

    &:first-child {
      a {
        padding-left: 0;
      }
    }
  }
}

.l-mobile-menu {
  padding: 25px 10px;
  text-align: center;
  background: $black;

  display: none;

  .mobile-menu-active & {
    display: block;
  }

  a.mobile-menu-close {
    display: block;
    float: left;
    margin-left: 10px;
    margin-bottom: 20px;
    text-decoration: none;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;

    .line {
      display: block;
      width: 100%;
      height: 1px;
      background: #fff;
      position: absolute;
      top: 15px;

      &:first-child {
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:last-child {
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }

  .block-search,
  .block--locale {
    display: none;
  }

  #block-block-1,
  #block-system-navigation,
  #block-system-user-menu,
  #block-system-main-menu {
    padding: 50px 20px 0;
    clear: both;
    display: block;

    .block__title {
      display: none;
    }

    ul.menu {
      li {
        margin: 0;
        width: 100%;
        padding: 0 5px 15px;

        a {
          font-size: 15px;
          padding: 5px 30px 5px 0;
          color: #fff;
        }

        ul {
          display: none;
        }
      }
    }
  }

  #block-block-1 {
    padding: 0 15px;
    float: left;
    margin-top: 30px;

    a {
      color: #fff;
      line-height: 40px;
    }

    a.log-in-button {
      border: 2px solid $red;
    }
  }

  #block-mazeblock-user-block {
    padding: 10px 20px;
    display: block;
    float: left;

    .user-block-wrapper {
      text-align: left;
    }

    .user-icon {
      color: $red;
      background: transparent;
      margin: 0;
      padding: 0 5px;
    }

    .options-wrapper ul {
      position: relative;
      display: block;
      background: transparent;
      box-shadow: none;
      padding: 0;

      li a {
        background: transparent;
        color: #fff;
        padding: 0 5px;
        border: 0;
        line-height: 35px;
      }

      li:last-child a {
        border: 0;
      }
    }
  }
}

.user-menu {
  display: none;

  @include breakpoint($desk) {
    display: inline-block;
    float: right;
    position: relative;
    padding-bottom: 15px;
    margin-top: 72px;
    display: block;

    ul.menu li {
      padding: 0;
    }

    h2 {
      font-size: 15px;
      color: $black;
      margin: 0;
      font-weight: 300;

      &:before {
        content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 8px solid $red;
      left: -18px;
      top: 6px;
      }
    }

    &:hover {
      ul.menu {
        display: block;
      }
    }

    ul.menu {
      position: absolute;
      display: none;
      background: #fff;
      padding: 0;
      z-index: 100;
      top: 30px;
      right: 0;
      width: 200px;
      height: auto;
      box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.24);
      li {
        list-style: none;
        list-style-image: none;
        width: 100%;
        a {
          padding: 0 30px;
          line-height: 46px;
          font-size: 15px;
          width: 100%;
          display: block;
          border-bottom: 1px solid $red;

          &:hover {
            text-decoration: none;
            background: $red;
            color: #fff;
          }
        }

        &:last-child {
          a {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

#block-mazeblock-user-block {
  display: inline-block;
  float: right;
  padding: 11px 6px;
  display: none;


  .user-block-wrapper {
    position: relative;
    height: 40px;
    width: 200px;
    text-align: right;
  }

  @include breakpoint($desk) {
    display: block;

    &:hover {
      .options-wrapper ul {
        display: block;
      }

      .user-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        text-align: right;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .user-icon {
    line-height: 40px;
    background: $black;
    color: #fff;
    border-radius: 3px;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    z-index: 101;
    position: relative;
    padding: 0 20px;
    margin-left: 10px;
  }

  .options-wrapper {
    position: relative;
    width: 100%;

    ul {
      position: absolute;
      display: none;
      background: $black;
      padding: 40px 0 0;
      z-index: 100;
      text-align: left;
      top: 0;
      right: 0;
      border-radius: 3px;
      overflow: hidden;
      width: 200px;
      height: auto;
      box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.24);
      li {
        list-style: none;
        list-style-image: none;
        a {
          padding: 0 30px;
          background: #fff;
          line-height: 46px;
          font-size: 15px;
          color: $black;
          width: 100%;
          padding: 0 20px;
          display: block;
          border-bottom: 1px solid $black;

          &:hover {
            text-decoration: none;
            background: $red;
            color: #fff;
          }
        }

        &:last-child {
          a {
            border-bottom: 5px solid $red;
          }
        }
      }
    }
  }
}

#block-block-3 {
  padding: 40px 0;
  background: $grey;

  .block__content {
    @extend .container;
    @include pie-clearfix;
    padding: 0 40px;

    h3 {
      font-size: 25px;
      font-style: italic;
    }

    a {
      background: $red;
      line-height: 45px;
      padding: 0 25px;
      display: inline-block;
      margin-top: 40px;
      color: #fff;
      text-decoration: none;

      &:hover {
        background: $black;
      }
    }

    img {
      float: left;
    }

    .info {
      float: right;
      width: calc(100% - 600px);
      padding: 0 30px;
    }
  }
}

.course-teaser {
  @include pie-clearfix;
  padding: 0 10px;
  margin-bottom: 40px;
  position: relative;

  .coming-soon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    text-align: center;
    background: rgba(255, 255, 255, 0.6);

    h4 {
      padding-top: 80px;
      font-size: 20px;
      margin: 0;
    }
  }

  .views-field-field-image {
    margin-bottom: 20px;
    height: 125px;
    overflow: hidden;
  }

  .views-field-field-lesson {
    background: $red;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 100px;
    left: 10px;
    padding: 0 5px;
  }

  .views-field-field-instrument,
  .views-field-name {
    font-size: 15px;
    opacity: 0.6;
  }

  .views-field-title {
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    padding: 5px 0;
    min-height: 60px;
  }
}

.featured-course {
  @include pie-clearfix;
  padding: 60px 0;

  .views-field-field-image {
    float: left;
    width: 50%;
    margin-right: 50px;
    z-index: 1;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }
  }

  .views-field-field-instrument,
  .views-field-name  {
    font-size: 20px;
    opacity: 0.8;
  }

  .views-field-title {
    font-size: 40px;
    font-weight: 700;
    font-style: italic;
    padding: 10px 0;
  }

  .views-field-body {
    margin-top: 40px;
    opacity: 0.8;
  }
}

.latest-courses {
  margin: 40px 0 80px;

  .view-content {
    @include pie-clearfix;
  }
}

.latest-news {
  text-align: center;
  padding: 40px 0;

  .view-content {
    @include pie-clearfix;
  }

  .views-field-field-image {
    max-height: 200px;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

// Newsletter
.newsletter {
  color: $black;
  padding: 60px 0 0;
  text-align: center;

  .webform-client-form .form-item {
    float: none;
  }
}

#block-block-2 {
  background: $black;
  color: #fff;
  text-align: center;
  padding: 40px;

  h3 {
    margin: 0;
    margin-bottom: 10px;
  }

  a {
    margin-top: 40px;
    background: #fff;
    color: $black;
    display: inline-block;
    line-height: 40px;
    padding: 0 40px;
  }
}

// Footer region.
.l-footer {
  font-size: 15px;
  font-weight: 400;
  padding-top: 40px;
  background: $grey;
  margin-top: 40px;

  p {
    margin: 0;
  }

  .footer-bottom {
    border-top: 1px solid $red;
    color: $black;
    font-size: 14px;
    font-weight: 300;
    padding: 10px;

    .footer-bottom-inner {
      padding: 0 20px;
    }

    a {
      color: $black;
    }

    .copyrights,
    .footer-mazeblock {
      display: inline-block;
      width: 50%;
      float: right;
      text-align: right;
    }

    .footer-mazeblock {
      color: $red;

      a {
        color: $red;
      }
    }

    .copyrights {
      text-align: left;
      float: left;
    }
  }
}

.l-region--footer {
  @include pie-clearfix;
  padding-bottom: 20px;

  h4 {
    color: #fff;
  }

  & > div, & > nav {
    width: 100%;
    padding-left: 20px;

    @include breakpoint($tab) {
      float: left;
      width: 50%;

      @include breakpoint($desk) {
        padding-left: 0;
        padding-bottom: 0;
        width: 25%;
        padding: 0 20px;
      }
    }
  }

  h2 {
    padding-bottom: 15px;
    margin: 0 0 20px;
    position: relative;
    font-weight: 700;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding-bottom: 12px;
      display: block;

      a {
        font-size: 15px;
        padding: 0;
      }
    }
  }

  .find-us {
    @include pie-clearfix;
    margin-bottom: 40px;

    a.social {
      width: 40px;
      height: 40px;
      background: rgba(51, 51, 51, 0.75);
      color: #fff;
      border-radius: 4px;
      float: left;
      margin-right: 10px;
      display: inline-block;
      font-size: 18px;
      padding: 8px;
      position: relative;

      &:before {
        position: absolute;
        top: 11px;
        right: 11px;
      }

      &:hover {
        background: $red;
      }
    }

    // .facebook:before {
    //   @extend .font-icon;
    //   @extend .icon-facebook;
    // }

    // .twitter:before {
    //   @extend .font-icon;
    //   @extend .icon-twitter;
    // }

    // .youtube:before {
    //   @extend .font-icon;
    //   @extend .icon-youtube;
    // }

    // .linkedin:before {
    //   @extend .font-icon;
    //   @extend .icon-linkedin;
    // }

    // .google:before {
    //   @extend .font-icon;
    //   @extend .icon-google-plus;
    // }
  }
}

.not-logged-in.page-user-password,
.not-logged-in.page-user-login,
.not-logged-in.page-user-register {
  .content-wrapper {
    margin: 0 auto 100px;
    display: block;
    width: 100%;
    max-width: 480px;

    input[type="submit"] {
      margin: 0 auto;
      display: block;
    }
  }
}

.node--page--full {
  padding: 60px 20px 100px;

  h1 {
    font-size: 32px;
    margin: 0 0 30px;
  }

  .field--name-body {
    line-height: 19px;
    width: 100%;
    max-width: 720px;
    margin: 0 auto;

    .node-type-webform & {
      text-align: center;
      margin-bottom: 60px;

      h4 {
        font-weight: 4px;
        font-size: 15px;
      }

      .phone,
      .email {
        display: inline-block;
        margin: 0 10px;
        font-size: 20px;
      }
    }
  }

  .webform-client-form {
    width: 100%;
    max-width: 840px;
    margin: 0 auto;
    display: block;
  }
}

// Teachers
.teachers-banner {
  height: 400px;
  width: 100%;
  background: url(../images/pexels-teachers.jpeg);
  background-size: cover;
  background-position: center;
  padding-top: 150px;

  h2 {
    font-size: 30px;
    text-align: center;
    margin: 0;
    color: #fff;
    // background: $red;
    padding: 5px 10px;
    max-width: 600px;
    margin: 0 auto;
  }
}

.view-teachers {
  margin-top: 80px;

  .front & {
    margin: 0;
  }

  .view-header {
    text-align: center;
    font-size: 20px;
    padding-bottom: 40px;
    font-style: italic;
  }

  .view-content {
    @include pie-clearfix;
  }

  .views-field-picture {
    margin-bottom: 20px;
  }

  .views-row {
    padding: 20px;
    margin-bottom: 40px;
  }

  .views-field-field-instrument {
    font-size: 15px;
    opacity: 0.6;
  }

  .fullname {
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    padding: 5px 0;
  }
}

// Courses
.course-listing {
  .view-header h2 {
    font-size: 30px;
    text-align: center;
    margin: 40px auto;
  }

  .view-filters {
    @include pie-clearfix;
    margin-bottom: 60px;

    // .views-exposed-form .views-exposed-widget {
    //   float: none;
    // }

    .form-type-bef-link {
      @extend .grid-1-3;
      text-align: center;
      padding: 5px;

      a {
        line-height: 45px;
        font-size: 17px;
        background: $grey;
        display: block;
        width: 100%;
        height: 100%;

        &.active {
          background: $red;
          color: #fff;
        }
      }
    }
  }
}

// Course taxonomy Page
.page-taxonomy-term {

  .l-content.container {
    width: 100% !important;
  }

  h1 {
    font-size: 50px;
  }

  .term-background {
    width: 100%;
    height: 400px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 80px;

    h1 {
      padding-top: 150px;
      margin: 0;
      color: #fff;
    }
  }

  .view-courses {
    @include pie-clearfix;
    @extend .container;
  }
}

// News listing
.news-listing {
  .view-filters {
    @include pie-clearfix;
    margin-bottom: 60px;

    .views-exposed-form .views-exposed-widget {
      float: none;
    }

    .form-type-bef-link {
      @extend .grid-1-3;
      text-align: center;
      padding: 5px;

      a {
        line-height: 45px;
        font-size: 17px;
        background: $grey;
        display: block;
        width: 100%;
        height: 100%;

        &.active {
          background: $red;
          color: #fff;
        }
      }
    }
  }
}

.news-teaser {
  @include pie-clearfix;

  .views-field-field-image {
    margin-bottom: 20px;
    height: 200px;
    overflow: hidden;


    img {
      width: 100%;
      height: 100%;
    }
  }

  .views-field-field-blog-category {
    font-size: 14px;
    opacity: 0.7;
  }

  .views-field-title {
    font-size: 20px;
    padding: 5px 0;
  }
}

.cookie-wrapper {
  background: $red;
  padding: 20px 0;
  color: #fff;
  text-align: center;

  button {
    background: #000;
    border-radius: 20px;
    color: #d23f3a;
    padding: 5px 15px;
    border: 1px solid #000;
    margin-left: 25px;
    width: auto;
  }
}

.view-footer {
  text-align: center;

  .view-all {
    background: $red;
    color: #fff;
    border-radius: 3px;
    outline: none;
    font-size: 15px;
    text-transform: uppercase;
    border: 0;
    padding: 0 20px;
    line-height: 40px;
    display: inline-block;
    margin: 10px auto 0;
    font-weight: 700;
    text-align: center;
  }
}
