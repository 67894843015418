body.mobile-menu-active {
  height: 100%;
  overflow: hidden;
}

body.mobile-menu-active {
  -webkit-transform: translate3d(-260px, 0, 0);
  -moz-transform: translate3d(-260px, 0, 0);
  transform: translate3d(-260px, 0, 0);
}

.l-mobile-menu {
  display: block;
  position: absolute;
  z-index: 499;
  top: 0;
  right: -260px;
  width: 260px;
  height: 100%;
  background: #fff;
  overflow: auto;
  box-shadow: none;
  display: none;

  body.mobile-menu-active & {
    display: block;
    box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.5);
  }

  @include breakpoint($desk) {
    display: none;
  }
}

.l-mobile-overlay {
  @include opacity(0);
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 0px;
  z-index: 498;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
  -moz-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
  transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;

  body.mobile-menu-active & {
    @include opacity(1);
    width: 100%;
    height: 100%;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }

  @include breakpoint($desk) {
    display: none;
  }
}

ul.menu {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    list-style-type: none;
    display: inline-block;

    a {
      padding: 5px 10px;

      &:hover {
        text-decoration: none;
        color: $red;
      }
    }

    &:first-child {
      a {
        padding-left: 0;
      }
    }

    ul {
      li {
        display: block;

        &:first-child {
          a {
            padding-left: 5px;
          }
        }
      }
    }
  }
}

.pager {
  margin-top: 20px;
  li > a {
    border: 0;
  }

  .pager__item {
    padding: 3px;
  }

  .pager__item a {
    color: $red;
    border-radius: 30px;
    padding: 0 15px;
    display: inline-block;
    min-width: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #000;
    &:hover,
    &:focus {
      background-color: $red;
      color: #000;
    }
  }

  .pager__item--current {
    background-color: #f8f8f8;
    border-radius: 30px;
    padding: 0 15px;
    display: inline-block;
    min-width: 30px;
    line-height: 30px;
    text-align: center;
    margin: 0 3px;
  }

  .pager__item--last a,
  .pager__item--first a,
  .pager__item--previous a,
  .pager__item--next a {
    background-color: $red;
    color: #000;
    &:hover,
    &:focus {
      color: $red;
      background-color: #000;
    }
  }
}
