// Font variables.
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&subset=greek');

$custom_font: 'Source Sans Pro', sans-serif;
$custom_font_serif: $custom_font;
$line-height-base: 1.25;
$font-size-base: 16px;
$font-size-large: 20px;
$font-size-small: 12px;
$font-size-h1: 30px;
$font-size-h2: 20px;
$font-size-h3: 18px;
$font-size-h4: 16px;
$font-size-h5: 14px;
$font-size-h6: 12px;
$headings-font-weight: 400;

// Other variables
$border-radius-base: 0;
$brand-primary: #000;
$text-color: #000;

// Breakpoint variables.
$screen-xs: 320px;
$screen-sm: 720px;
$screen-md: 1140px;
$screen-lg: 1310px;
$mob: $screen-xs $screen-sm;
$tab: $screen-sm;
$desk: $screen-md;
$wide: $screen-lg;

// Grid variables.
$container-tablet: 720px;
$container-desktop: 1000px;


// Sidebar
$sidebar_tab: 150px;
$sidebar_desk: 210px;

$red: #ef4030;
$orange: #de4d3a;
$eden: #303030;
$grey: #f2f2f2;
// $grey: #f5f7fa;
$medium-grey: #f5f7fa;
$dark-grey: #828282;

$blue: #1d83bd;
$black: #242527;
