.profile {
  @include pie-clearfix;
  margin-bottom: 60px;

  .user-profile-category-history {
    display: none;
  }

  .base {
    float: left;
    width: 30%;
  }

  .details {
    float: left;
    width: 70%;

    & > div {
      margin-bottom: 10px;
    }
  }

  .user-picture {
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid $blue;
    display: inline-block;
    margin-right: 40px;
    float: left;
  }

  .fullname {
    font-size: 20px;
  }
}

.dashbox {
  padding: 20px 0;

  h2 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
  }
}

#edit-picture,
.user-profile {
  .user-picture {
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.ms-products-user-purchase-options-form {
  margin: 40px 0;
}

.user-profile {
  position: relative;

  .account-settings-links {
        display: inline-block;
        top: 20px;
        width: 140px;
        text-align: center;
        right: 0;
        padding: 0 20px;
        line-height: 40px;
        background: $blue;
        color: #fff;
        font-family: 'Fira Sans', sans-serif;
        border-radius: 3px;
        margin-bottom: 10px;

            @include breakpoint($desk) {
    position: absolute;
              margin: 0;
            }

    &:hover {
      background: $black;
    }

    &.memberships-link {
      top: 70px;
    }
  }

  .field {
    display: inline-block;
    margin-right: 40px;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;

    .field__label {
      font-size: 13px;
      color: $blue;
      text-transform: uppercase;
    }
  }

  .user-profile-category-history {
    h2 {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

.user-profile-form {
  max-width: 540px;
  margin: 0 auto;

  fieldset.date-combo .container-inline-date .date-padding {
    padding: 0;
  }

  .field-type-datetime {
    legend {
      color: $blue;
      text-transform: uppercase;
      font-size: 12px;
      font-family: 'Lato', sans-serif;
    }
  }
}

.form-item-mail {
  position: relative;
}

.forgot-password {
  font-family: 'Fira Sans', sans-serif;
  margin-bottom: 20px;
  text-align: right;
}

.info-tooltip {
  position: absolute;
  z-index: 100;
  bottom: 11px;
  right: 15px;
  display: table;
  .info-icon {
    font-family: 'Fira Sans', sans-serif;
    display: block;
    width: 20px;
    height: 20px;
    line-height: 22px;
    text-align: center;
    border-radius: 25px;
    background: $blue;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    color: #fff;
  }
  .info-content {
    position: absolute;
    display: none;
    top: 22px;
    right: 0;
    width: 360px;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    background: #f6f9fc;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    border-radius: 4px;
  }

  &:hover .info-content {
    display: block;
  }
}


.sign-in-link,
.sign-up-link {
  margin-top: 50px;
  border-radius: 3px;
  background: #eff0ef;
  padding: 30px;

  &.sign-in-link-dark {
    background: #242527;
    color: #fff;
    margin-top: 10px;

    a {
      color: #fff;
    }
  }

  a {
    color: $blue;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    line-height: 20px;
    margin-left: 30px;
  }
}
