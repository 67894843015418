.node--article--full{
  width: 100%;

  h1 {
    text-align: left;
    font-size: 32px;
    line-height: 38px;
    margin: 0 0 15px;
    clear: both;
  }

  .date-wrapper {
    float: left;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;

    &.big {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .category-wrapper {
    float: right;

    .field--name-field-article-category {
      line-height: 16px;
    }

    a {
      display: inline-block;
      font-weight: 300;
      font-size: 13px;
    }
  }

  .publisher {
    float: left;
    a {
      font-weight: 300;
      font-size: 16px;
      line-height: 25px;
      color: $grey;
    }
  }

  .body-wrapper {
    line-height: 20px;
    font-size: 16px;
    p {
      margin: 0 0 25px;
    }

    a {
      text-decoration: underline;
    }
  }

  .image-wrapper {
    padding: 10px 0 20px;
    clear: both;
    img {
      width: 100%;
      height: auto;
    }
  }

  .tags-wrapper {
    margin: 30px 0 50px;
    position: relative;
    font-size: 12px;
    padding-top: 10px;
    padding-left: 10px;
    font-weight: 700;

    &:before {
      content: '#';
      display: block;
      position: absolute;
      top: 10px;
      left: 0;
    }

    .field__item {
      display: inline-block;
      position: relative;
      margin-right: 6px;
      border-bottom: 1px solid $red;

      a:hover {
        color: $red;
        text-decoration: none;
      }

      &:after {
        content: ',';
        position: absolute;
        top: 0;
        right: -3px;
        display: block;
      }

      &:last-child:after {
        display: none;
      }
    }
  }

  .youtube-wrapper {
    margin-bottom: 30px;
    text-align: center;

    iframe {
      width: 320px;
      height: 160px;

      @include breakpoint($tab) {
        width: 540px;
        height: 350px;
      }
    }
  }

  .share-product {
    float: right;

    h4 {
      margin: 0 8px 0 0;
      font-size: 13px;
      line-height: 25px;
      float: left;
      font-weight: 700;
      display: inline-block;
    }

    .share-product-wrapper {
      display: inline-block;

      a {
        display: inline-block;
        height: 25px;
        width: 25px;
        border-radius: 20px;
        background: $red;
        margin: 0;
        font-size: 0;
        text-align: center;
        position: relative;
        overflow: hidden;
        color: transparent;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          line-height: 25px;
          display: block;
          color: #000;
          top: 0;
          right: 0;
        }

        &:hover,
        &:focus {
          &:after {
            background: #000;
            color: $red;
            text-decoration: none;
          }
        }
      }

      .facebook-share:before,
      .google-share:before,
      .twitter-share:before,
      .email-share:before {
        color: #333;
        font-size: 14px;
        line-height: 25px;
      }

      // .facebook-share:before {
      //   @extend .font-icon;
      //   @extend .icon-facebook;
      // }
      // .google-share:before {
      //   @extend .font-icon;
      //   @extend .icon-google;
      // }
      // .twitter-share:before {
      //   @extend .font-icon;
      //   @extend .icon-twitter;
      // }
      // .email-share:before {
      //   @extend .font-icon;
      //   @extend .icon-mail;
      // }
    }
  }
}

.node--article--full {
  .image-wrapper {
    @include pie-clearfix;
    @include clearfix;

    @include breakpoint($tab) {
      width: 55%;
      padding-right: 20px;
      float: left;
    }
  }

  .body-wrapper {
    margin-top: 10px;
  }
}


